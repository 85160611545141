const city_names = [
	"Aberdeen",
	"Bath",
	"Birmingham",
	"Brighton",
	"Bristol",
	"Cambridge",
	"Cardiff",
	"Chester",
	"Edinburgh",
	"Glasgow",
	"Inverness",
	"Leeds",
	"Liverpool",
	"London",
	"Manchester",
	"Newcastle",
	"Nottingham",
	"Oxford",
	"Reading",
	"York"
];

export default city_names